import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { val }  from '../components/cssvalue'
import {PanelWrap} from '../pages/index.js'
import {MainWrap} from '../pages/web.js'

// STYLE
const About = ({data, location}) => {
  return (
    <Layout location={location}>
      <Seo
        title="事務所概要"
        description=""
        pagepath={location.pathname}
      />
      <MainWrap>
        <div className="-jacket">
          <h1>事務所概要</h1>
          <PanelWrap>
            <div className="-inner">
              <p className="-p">
                TAN-ZENT（たんぜん）は、WEB制作経験20年以上のベテランWEB制作者のフリーランスグループです。<br />
                神奈川県三浦半島を拠点としており、対面や訪問が必要であれば神奈川県と東京都、必要なければ日本全国を対象エリアとしています。<br />
                2018年に神奈川に来る前は東京都港区と渋谷区を拠点としていました。
              </p>
              <p className="-p">
                主な業務内容はホームページの制作と運用で、具体的には広告やWEBサイトのデザイン・設計構築・ブランディング・運用支援等を手がけています。
              </p>
              <p className="-p">
                主要メンバーは、アートディレクター兼デザイナー、フロントエンドをメインとしながらCMS構築までをカバーするフルスタックエンジニア、サイトプランニング・プロジェクトマネジメントから運用までを一貫して担当するウェブディレクターが在籍しています。
              </p>
              <p className="-p">
                経験豊富なプロフェッショナルパートナーとして、SEO等の検索結果の最適化、パフォーマンス、運用の安定性と負荷軽減を適える設計等のテクニカル面と共に、ブランドイメージや目的に沿ったクオリティの高いデザインをご提案します。
              </p>
            </div>
          </PanelWrap>
        </div>
      </MainWrap>
    </Layout>
  )
}
export default About
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`
